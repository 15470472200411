import React, { useEffect, useState } from "react"
import { ProgressBar } from "baseui/progress-bar"

export default () => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = () => {
      const winScroll = document.documentElement.scrollTop
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      const scrolled = (winScroll / height) * 100
      setScrollTop(scrolled)
    }
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <ProgressBar
      value={scrollTop}
      overrides={{
        BarContainer: {
          style: {
            outline: `white solid 12px`,
            backgroundColor: "white",
          },
        },
        BarProgress: {
          style: {
            backgroundColor: "#16806F",
          },
        },
      }}
    />
  )
}
