import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Sticky from "react-stickynode"
import { Block } from "baseui/block"
import { Avatar } from "baseui/avatar"
import Img from "gatsby-image"
import {
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"
import Progressbar from "../components/BlogProgressBar"
import { SEOPost } from "../components/SEO"
import Layout from "../components/layout"
import Container from "../components/UI/Container"
import Recommendation from "../containers/Recommendation"
import content from "../../content/data/recommendation-content.json"
import {
  Author,
  DateInfo,
  TagList,
  RecommendationCard,
} from "../components/BlogComponents"

const SocialButtons = ({ url, title }) => (
  <Block>
    <LinkedinShareButton url={url}>
      <LinkedinIcon size={38} />
    </LinkedinShareButton>
    <WhatsappShareButton url={url} title={title}>
      <WhatsappIcon size={38} />
    </WhatsappShareButton>
    <TelegramShareButton url={url} title={title}>
      <TelegramIcon size={38} />
    </TelegramShareButton>
    <TwitterShareButton url={url} title={title}>
      <TwitterIcon size={38} />
    </TwitterShareButton>
    <RedditShareButton url={url} title={title}>
      <RedditIcon size={38} />
    </RedditShareButton>
  </Block>
)

const Post = ({ data }) => {
  // data.markdownRemark holds your post data
  const { mdx: post } = data
  return (
    <>
      <Layout isSimple>
        <Sticky innerZ={1} top={9.5}>
          <Block paddingTop={["50px", "55px", "58px"]}>
            <Progressbar />
          </Block>
        </Sticky>
        <SEOPost
          title={post.frontmatter.title}
          excerpt={post.frontmatter.description}
          date={post.frontmatter.date}
          imagePublicURL={post.frontmatter.hero_image}
          slug={post.fields.slug}
          author={post.frontmatter.author}
        />
        <Block paddingTop={["80px", "80px", "80px", "120px"]}>
          <Container width="780px" className="post-content">
            <TagList tags={post.frontmatter.tags} />
            <h1 style={{ marginTop: 0 }}>{post.frontmatter.title}</h1>
            <p style={{ color: "#486581", marginTop: "0" }}>
              {post.frontmatter.description}
            </p>
            <div className="post-divider">
              <Block
                display="flex"
                flexDirection="row"
                flexWrap={["wrap", "wrap", "nowrap"]}
                justifyContent="space-between"
                alignItems="last baseline"
                paddingBottom="28px"
              >
                <Block display="flex" flexDirection="row">
                  <Avatar name={post.frontmatter.author} size="scale1000" />
                  <Block display="flex" flexDirection="column">
                    <Author>{post.frontmatter.author}</Author>
                    <DateInfo>
                      {`${post.frontmatter.date} · ${post.timeToRead} min read`}
                    </DateInfo>
                  </Block>
                </Block>
                <SocialButtons
                  url={`http://www.cylynx.io${post.fields.slug}`}
                  title={post.frontmatter.title}
                />
              </Block>
            </div>
          </Container>
          <Container width="1000px" className="post-content-image">
            {post.frontmatter.hero_image ? (
              <Img
                fluid={post.imgixImage.fluid}
                style={{ width: "100%" }}
                alt={post.frontmatter.title}
              />
            ) : null}
          </Container>

          <Container width="780px" className="post-content">
            <Block paddingTop="30px">
              <MDXRenderer className="post-content">{post.body}</MDXRenderer>
              <Block marginTop={["50px", "50px", "100px"]} />
              <hr />
            </Block>
          </Container>
          {post.frontmatter.recommendations && (
            <Recommendation>
              {post.frontmatter.recommendations.map(rec => {
                return (
                  content[rec] && (
                    <RecommendationCard
                      slug={`${content[rec]["slug"]}`}
                      title={content[rec]["title"]}
                      description={content[rec]["description"]}
                      imgSrc={content[rec]["imgSrc"]}
                      key={content[rec]["slug"]}
                    />
                  )
                )
              })}
            </Recommendation>
          )}
        </Block>
      </Layout>
    </>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        author
        tags
        hero_image
        recommendations
      }
      imgixImage {
        fluid(maxWidth: 1080) {
          ...GatsbyImgixFluid
        }
      }
      fields {
        slug
      }
    }
  }
`
